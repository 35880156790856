<template>
  <div>
    <div class="mb-2">
      <div class="div-header">
        <b-row class="d-flex align-items-center">
          <b-col class="d-flex align-items-center">
            <b-form-checkbox
              class="input-plan"
              size="lg"
              :value="product.id"
              v-model="items"
              :disabled="product.mandatory == '1'"
            ></b-form-checkbox>
            <small class="text-muted">{{ product.product }}</small>
          </b-col>
          <b-col class="d-flex justify-content-end" md="3">
            <div>
              <span class="h5 text-muted" style="margin-bottom: unset">{{
                toMonetary(product.total)
              }}</span>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        class="div-body"
        :class="{ 'border border-danger': errors.includes(product.id) }"
        v-if="
          items.includes(product.id) && product.productinstallment.length > 0
        "
      >
        <b-form-radio
          v-for="installment in product.productinstallment"
          :value="installment"
          :key="installment.id"
          @change="change_installment($event)"
          v-model="installmentSelected"
          :name="`radio-enrollment-${installment.id}`"
          ><small>{{ installment.description }}</small></b-form-radio
        >
      </div>
      <small class="text-danger" v-if="errors.includes(product.id)"
        >Selecione uma opção acima</small
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "product", "errors"],
  data() {
    return {
      items: [],
      installmentSelected: [],
    };
  },
  watch: {
    items: {
      handler(newValue) {
        if (newValue.length > 0) {
          const item = {
            product_id: this.product.id,
            group_installment_id: "",
          };
          this.add_item(item);
        } else {
          this.remove_item();
        }
      },
    },
  },
  methods: {
    change_installment(value) {
      const item = {
        product_id: value.product_id,
        group_installment_id: value.group_installment_id,
      };
      this.add_item(item);
    },
    add_item(value) {     
      const index = _.findIndex(this.value.items, (item) => {
        return value.product_id == item.product_id;
      });
      if (index > -1) {
        this.value.items.splice(index, 1, value);
      } else {
        this.value.items.push(value);
      }
    },
    remove_item() {
      const index = _.findIndex(this.value.items, (item) => {
        return this.product.id == item.product_id;
      });
      if (index > -1) {
        this.installmentSelected = [];
        this.value.items.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.product.mandatory == "1") {
      this.items.push(this.product.id);
    }
  },
};
</script>

<style>
</style>